import PbContentCaption from "@/components/content/pbContentCaption/pbContentCaption";
import PbContentImage from "@/components/content/pbContentImage/pbContentImage";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrTypography from "@/hooks/useStrTypography";
import { ContentElementImageStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEImage } from "@/types/content-elements";
import { getObjectFitValue } from "@/utils/imageUtil";
import {
  getCssMaxWidthValue,
  getPbContentScopedSelector
} from "@/utils/util";
import clsx from "clsx";
import React from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import ImageContentElementStyleGlobal from "./imageContentElementStyleGlobal";
import ImageContentElementStyleScoped from "./imageContentElementStyleScoped";

export interface ImageContentElementProps {
  content: CEImage;
  position: number;
  ceSettings?: ContentElementImageStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const IMAGE_CE_NAME = "imageCE";

export default React.memo(function ImageContentElement(
  props: ImageContentElementProps
) {
  const IMAGE_SCOPED_CLASS = useScopedClassName(IMAGE_CE_NAME, props.content, props.position);
  const tCms = useCmsTranslation();

  const { typographyClassName } = useStrTypography(
    props.content.cfgStrTypography,
    props.ceSettings?.typographys
  );

  return (
    <>
      <ContentElementWrapper
        name={IMAGE_CE_NAME}
        scopedClassName={IMAGE_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div className={clsx(IMAGE_CE_NAME, IMAGE_SCOPED_CLASS)}>
          <PbContentImage
            img={props.content.img}
            cmsPosition={props.position}
            cmsFieldImage={"img"}
            maxWidth={getCssMaxWidthValue(
              props.ceSettings?.maxWidthImagePX,
              props.content.cfgImageIgnoresMaxWidth
            )}
            height="auto"
            width="100%"
            isMobile={props.isMobile}
            objectFit={getObjectFitValue(props.content.cfgImageObjectFit)}
            responsive={{
              mobile: props.ceSettings?.maxHeightImage.mobile ?? 200,
              tablet: props.ceSettings?.maxHeightImage.tablet ?? 300,
              desktop: props.ceSettings?.maxHeightImage.desktop ?? 400,
              wqhd: props.ceSettings?.maxHeightImage.wqhd ?? 500,
            }}
            skipOptimization={props.content.cfgUseHighestImageQuality}
            placeholderHeight={300}
            placeholderWidth={150}
            widthLimitSettings={
              props.content.cfgImageIgnoresMaxWidth
                ? undefined
                : props.ceSettings?.maxWidthImagePX
            }
          ></PbContentImage>
        </div>
        {!props.content.cfgHideCaption && (
          <PbContentCaption
            scopedSelector={getPbContentScopedSelector(IMAGE_SCOPED_CLASS)}
            textAlign={props.content.cfgCaptionTextAlign}
            cmsField={"imgCaption"}
            cmsPosition={props.position}
            content={props.content.imgCaption}
            cmsPlaceholder={tCms("enterCaptionHere")}
            className={typographyClassName}
          />
        )}
      </ContentElementWrapper>
      <ImageContentElementStyleGlobal {...props} />
      <ImageContentElementStyleScoped
        {...props}
        scopedSelector={IMAGE_SCOPED_CLASS}
      />
    </>
  );
});
