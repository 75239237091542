import { IMAGE_CE_NAME, ImageContentElementProps } from "./imageContentElement";

/**
 * This is the global style of a CE.
 * This style is applied on all instances of this CE
 *
 * @param props
 * @returns
 */
export default function ImageContentElementStyleGlobal(
  props: ImageContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${IMAGE_CE_NAME}) {
        }
      `}
    </style>
  );
}
