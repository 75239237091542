
export default function PbContentCaptionStyleGlobal(
) {
  return (
    <style jsx global>{`
      .cms-content-caption {
        background-color: transparent;
        width: 100%;
        border: none;
        display: block;
      }

      .cms-content-caption,
      .cms-content-caption-wrapper {
        word-break: break-word;
      }

      .cms-content-caption:focus {
        outline: none;
      }
    `}</style>
  );
}
