import { ImageContentElementProps } from "./imageContentElement";

interface ImageContentElementStyleScopedProps extends ImageContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function ImageContentElementStyleScoped(
  props: ImageContentElementStyleScopedProps
) {
  const filter = props.content.cfgHasOverlay ? "brightness(0.7)" : "none";
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        display: flex;
        justify-content: center;
        filter: ${filter};
      }
    `}</style>
  );
}
