import CmsContentCaption from "@/components/cms/cmsContent/cmsContentCaption";
import PbContentCaptionStyleGlobal from "@/components/content/pbContentCaption/pbContentCaptionStyleGlobal";
import { useAppSelector } from "@/store/store";
import clsx from "clsx";
import PbContentCaptionStyleScoped from "./pbContentCaptionStyleScoped";

export interface PbContentCaptionProps {
  /**
   * Contenttype this component is used by
   */
  contentType?: string;
  /**
   * Field name in the content array
   */
  cmsField: string;
  /**
   * Position in content array
   */
  cmsPosition: number;
  /**
   * Disables editview for this element
   */
  disableEditView?: boolean;
  /**
   * string content
   */
  content: string;
  /**
   * Centers the caption
   */
  center?: boolean;
  /**
   * Placeholder of the input element
   */
  cmsPlaceholder: string;
  /**
   * Text position
   */
  textAlign?: string;
  /**
   * Additional class names to apply to the caption
   */
  className?: string;
  /**
   * Scopedselector for caption content
   */
  scopedSelector?: string;
}

const PbContentCaption = (props: PbContentCaptionProps) => {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const scopedSelector = `${props.scopedSelector}-content-caption`;

  return (
    <>
      {editView && !props.disableEditView ? (
        <CmsContentCaption {...props} scopedSelector={scopedSelector} />
      ) : (
        <>
          {props.content && (
            <figcaption
              className={clsx(
                props.center && "center",
                scopedSelector,
                "cms-content-caption",
                props.className
              )}
            >
              {props.content}
            </figcaption>
          )}
        </>
      )}
      <PbContentCaptionStyleGlobal />
      <PbContentCaptionStyleScoped {...props} scopedSelector={scopedSelector} />
    </>
  );
};

export default PbContentCaption;
