import { PbContentCaptionProps } from "@/components/content/pbContentCaption/pbContentCaption";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import clsx from "clsx";
import dynamic from "next/dynamic";
import React, { useRef } from "react";
const CmsMaxLengthIndicator = dynamic(
  () => import("@/components/cms/cmsMaxLengthIndicator/cmsMaxLengthIndicator")
);

export interface CmsContentCaptionProps extends PbContentCaptionProps {
  scopedSelector: string;
}

/**
 * Component for updating/displaying (editmode/editview) values
 * from the CMS page.content array
 * Do not use this for other purposes
 */
const CmsContentCaption = (props: CmsContentCaptionProps) => {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const dispatch = useAppDispatch();
  const captionTextInputRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.target.value = e.target.value.replace(/(\r\n|\n|\r)/gm, "");

    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsField}`,
        value: e.target.value,
      })
    );
  };

  return (
    <>
      {editView && !props.disableEditView ? (
        <>
          <div className="with-max-length-indicator">
            <div
              className="grow-wrap cms-content-caption-wrapper"
              data-replicated-value={props.content}
            >
              <textarea
                ref={captionTextInputRef}
                maxLength={250}
                rows={1}
                onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  if (e.target.parentNode instanceof HTMLElement) {
                    e.target.parentNode.dataset.replicatedValue =
                      e.target.value;
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                className={clsx(
                  props.center && "center",
                  (props.content === "" || props.content === null) &&
                    "no-caption",
                  props.scopedSelector,
                  "cms-content-caption",
                  props.className
                )}
                defaultValue={props.content ?? ""}
                placeholder={!props.content ? props.cmsPlaceholder : ""}
                onChange={handleChange}
              />
            </div>
            <CmsMaxLengthIndicator
              maxLength={250}
              length={captionTextInputRef?.current?.value.length ?? 0}
            />
          </div>
        </>
      ) : null}
      <style jsx>{`
        /* Richtext-like styling in Edit-View if no caption was entered */
        .cms-content-caption-wrapper {
          .no-caption {
            border: 1px solid #ccc;
            border-radius: 5px;
            margin-top: 1px;
            margin-bottom: 1px;
            max-width: 50%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      `}</style>
    </>
  );
};

export default CmsContentCaption;
