import { PbContentCaptionProps } from "@/components/content/pbContentCaption/pbContentCaption";

interface PbContentCaptionStyleScopedProps extends PbContentCaptionProps {
  scopedSelector?: string;
}

export default function PbContentCaptionStyleScoped(
  props: PbContentCaptionStyleScopedProps
) {
  const textAlign = props.textAlign ?? "center";
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        text-align: ${textAlign};
      }
    `}</style>
  );
}
